import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import "./App.css";

import Header from "./components/header/Header";
import FooterWrapper from "./components/footer/Footer";
import Home from "./components/home/Home";
import About from "./components/about-us/about-us";
import FAQ from "./components/FAQ/faq";
import ContactUs from "./components/contact-us/contact-us";
import NotFound from "./notfound";
import Success from "./components/success/Success";
import Declined from "./components/declined/declined";


const App: React.FC = () => {
	return (
		<div className="App">
			<Router>
				<Header />

				<div id="main-container">
					<Switch>
						<Route exact path="/" component={Home} />
						<Route path="/faq" component={FAQ} />
						<Route path="/about-us" component={About} />
						<Route path="/success" component={Success} />
						<Route path="/declined" component={Declined} />
						<Route path="/contact-us" component={ContactUs} />
						<Route component={NotFound} />
					</Switch>
				</div>

				<div className="background-img-placeholder"></div>
			
				<FooterWrapper />
			</Router>
		</div>
	);
};

export default App;
