import React from "react";
import "./calculator.css";
import { ENDPOINTS } from "../../endpoints";


class Calculator extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			dateOpts: { year: 'numeric', month: '2-digit', day: '2-digit' },
			rate: null,
			currentDate: this.pad(new Date().getDate()) + "/" + 
				this.pad((new Date().getMonth() + 1)) + "/" + this.pad(new Date().getFullYear()),
			fee: 0,
			pkrValue: 0
		};
	}

	pad(n: number) {
		return n <10 ? '0' + n : n;
	}

	handleChange(e: any) {
		this.setState({
			pkrValue: e.target.value * this.state.rate
		});
	}

  	// Input is allowed upto 5 digits
	onkeydown(ev: any) {
		const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
		const charLength = ev.target.value.length;

		if (charLength < 5 || allowedKeys.includes(ev.key)) {
			return true;
		}
		else {
			ev.preventDefault();
		}
	}

	componentDidMount() {
		this.getRate();
	}

	getRate(): void {
		Promise.all([
			fetch(ENDPOINTS.FEE).then((res1) => res1.json()),
			fetch(ENDPOINTS.RATE).then((res2) => res2.json())
		])
		.then(([fee, rate]) => {
			this.setState({ rate: rate.data.rate.toFixed(2), fee: fee.data[0].feeAmount.toFixed(2) });
		})
		.catch((err) => {
			console.log(err);
		});
	}

  render() {
    return (
		<div className="square d-flex">
          	<div className="content">
				<h2 className="theme-purple calculate-section-heading"> Transfer & Rates</h2>

				<div className="d-flex justify-content-center mt-5">
					<div className="d-flex flex-column align-items-center">
						<div className="mb-2">GBP</div>
						<div className="container">
							<input type="number"
								onKeyDown={ (e) => { this.onkeydown(e) }}
								onChange={ (e) => { this.handleChange(e); }}
								className="input-field"/>

							<img src="./uk.svg" alt="placeholder text" className="image" />
						</div>
					</div>

					<img src="./arrow.svg" className="mt-5 align-items-end conversion-arrow" alt="Arrow" />

					<div className="d-flex flex-column align-items-center">
						<div className="mb-2">PKR</div>
						<div className="container">
							<input
								className="input-field"
								type="number"
								value={this.state.pkrValue}
								disabled
								id="input"/>

							<img src="./pkr.svg" alt="placeholder text" className="image" />
						</div>
					</div>
				</div>

				<div className="d-flex flex-column align-items-start mb-5 conversion-display">
					<div className="cal-input p-2">
						<span>Todays rate</span>
						<span className="dashed-separator"></span>
						<span>1.00 GBP = {this.state.rate} PKR</span>
					</div>

					<div className="cal-input p-2">
						<span>Fee</span> 
						<span className="dashed-separator"></span> 
						<span>{ this.state.fee } GBP</span>
					</div>

					<div className="cal-input p-2">
						<span>Date</span>
						<span className="dashed-separator"></span>
						<span>{ this.state.currentDate }</span>
					</div>
				</div>
			</div>
        </div>
    );
  }
}

export default Calculator;
