import React from "react";

import "./get-started.css";

class GetStarted extends React.Component {
	render() {
		return (
			<div>
				<div className="d-flex flex-wrap mt-5 justify-content-around align-items-center">
					<div className="get-started">
						<h3 className="f-600 theme-text-color mb-4"> Get The App - Get Started </h3>
						
						<div>
							<a href="https://play.google.com/store/apps/details?id=com.vowpay.vowpay" target="_blank" rel="noopener noreferrer">
								<img src="./google-play.png" className="mr-4 app-image" alt="Playstore"/>
							</a>

							<a href="https://itunes.apple.com/us/app/vowpay/id1095042185?ls=1&amp;mt=8" target="_blank" rel="noopener noreferrer">
								<img src="./app-store.png" className="app-image" alt="App store"/>
							</a>
						</div>

						<div className="mt-4">
							<img src="./visa_img.png" className="mr-4" alt="Visa"/>
							<img src="./master.png" alt="Master"/>{" "}
						</div>
					</div>

					<img src="./two-phones.svg" className="two-phones" alt=""/>
				</div>
			</div>
		);
  }
}

export default GetStarted;
