import React from "react";


class FooterMobile extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			year: new Date().getFullYear()
		};
	}

	render() {
		return (
			<div className="footer-mobile theme-text-color">
				Copyrights © Vowpay Ltd {this.state.year}
			</div>
		);
	}
}

export default FooterMobile;
