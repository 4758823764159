import React from "react";


class FooterDesktop extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			year: new Date().getFullYear()
		};
	}

	render() {
		return (
		<div className="footer-desktop  theme-text-color">
			<span className="text-center">Copyrights © Vowpay Ltd {this.state.year}</span>
			
			<span className="float-right mr-5">
				<img src="./pci.png" alt="PCI" className="pci-img" />{" "}
			</span>
		</div>
		);
	}
}

export default FooterDesktop;
