import React, { useEffect, useState } from 'react'
import './declined.css';
import { ENDPOINTS } from '../../endpoints';
const Declined = () => {

    const [data, setData] = useState<any>({});   

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        let obj: any = {};
       
        params.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            obj[key] = value;
        });

        setData(obj);
    }, [])

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            console.log(data);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...data })
            };


            fetch(ENDPOINTS.NOTIFY, requestOptions)
                .then(response => {                    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }                    
                    return response.json();
                })
                .then(data => {                    
                    console.log('Response:', data);
                })
                .catch(error => {                   
                    console.error('There was a problem with the fetch operation:', error);
                });

        }
    }, [data])
    return (
        <div className='declined-page'>
            <div className="declined-container">
                <div className="declined-icon">&#10060;</div>
                <div className="declined-title">Payment Declined</div>
                <div className="declined-message">Unfortunately, your payment could not be processed.</div>
                <div className="declined-details">
                <p>Order Reference: <strong>{data.orderreference}</strong></p>
                <p>Transaction Reference: <strong>{data.transactionreference}</strong></p>
               
                </div>
                {/* <a href="/" className="btn">Try Again</a> */}
            </div>
        </div>
    )
}

export default Declined