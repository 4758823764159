import React from "react";
import { NavLink } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler,  NavbarBrand, DropdownItem } from "reactstrap";

import "./header.css";


class Header extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	render() {
		return (
			<div id="header-container">
				<Navbar className="main-header header-line white-bg" light expand="md">
					<NavbarBrand>
						<NavLink id="main" to="/" className="theme-purple header-logo">
							<img src="./header-logo.svg" alt="vowpay-logo" width="50px" height="50px" />
							VOWPAY
						</NavLink>
					</NavbarBrand>

					<div className="navigation-links">
						<NavLink to="/about-us" className="nav-item">About</NavLink>
						<NavLink to="/faq" className="nav-item">FAQ'S</NavLink>
						<NavLink to="/contact-us" className="nav-item">Contact Us</NavLink>
					</div>

					<NavbarToggler onClick={this.toggle}>
						<div id="close-icon" className={this.state.isOpen ? "open" : ""}>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</NavbarToggler>

					<Collapse isOpen={this.state.isOpen} navbar>
						<div onClick={this.toggle} style={{ display: this.state.isOpen ? 'block' : 'none' }}>
							<DropdownItem className="dropdown-btn-link">
								<NavLink to="/about-us" className="dropdown-link">About</NavLink>
							</DropdownItem>

							<DropdownItem className="dropdown-btn-link">
								<NavLink to="/faq" className="dropdown-link">FAQ'S</NavLink>
							</DropdownItem>

							<DropdownItem className="text-center">
								<NavLink to="/contact-us" className="dropdown-link">Contact Us</NavLink>
							</DropdownItem>
						</div>
					</Collapse>
				</Navbar>
			</div>
		);
	}
}

export default Header;
