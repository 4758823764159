import React from "react";
import GetStarted from "../get-started/get-started";
import "./contact-us.css";


class ContactUs extends React.Component {
	render() {
		return (
			<div className="contact-us-container">
				<div className="d-flex flex-column text-left justify-content-start theme-text-color">
					<h2 className="f-600 theme-text-color"> Contact Us </h2>

					<div className="d-flex flex-column ml-5 contact-info">
						<div className="mt-5 mb-5 contact-info-text">
							<img src="./location.png" className="mr-4 contact-img" alt="Location" />
							<span>Thames House, Waterside Drive, Langley, Slough, SL3 6EZ, United Kingdom</span>
						</div>

						<div className="mb-5 contact-info-text">
							<img src="./mail.png" className="mr-4 contact-img" alt="Mail" />
							<span>support@vowpay.com</span>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-center flex-row">
					<GetStarted />
				</div>
			</div>
		);
	}
}

export default ContactUs;
