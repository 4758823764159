import React from "react";
import GetStarted from "../get-started/get-started";
import Calculator from "../calculator/calculator";

import './home.css';

class Home extends React.Component {
	render() {
		return (
		<div className="home-page">
			<section className="d-flex flex-wrap justify-content-between mt-5 first-section">
				<div className="mt-5 main-heading-section">
					<div>
						<h1 className="theme-text-color f-600 mt-4 mb-4 heading-primary">
							International Transfers <br /> <span className="mr-5"> Made Simple </span>
						</h1>
				
						<div className="d-flex flex-column align-items-start text-left secondary-app-text">
							<span className="theme-light-text text-container">
								<img src="./bullet.svg" alt="placeholder text" className="bullet-img-top"/>
								<div className="theme-light-text"> Enter Amount </div>
							</span>

							<span className="theme-light-text text-container">
								<img src="./bullet.svg" alt="placeholder text" className="bullet-img-top"/>
								<div className="theme-light-text"> Select Beneficiary </div>
							</span>

							<span className="theme-light-text text-container">
								<img src="./bullet.svg" alt="placeholder text" className="bullet-img-top"/>
								<div className="theme-light-text"> Send money Via Our Mobile Application </div>
							</span>
						</div>
					
						<div className="d-flex top-app-btns">
							<a href="https://play.google.com/store/apps/details?id=com.vowpay.vowpay" target="_blank" rel="noopener noreferrer">
								<img src="./google-play.png" alt="placeholder text" className="mt-5 mr-4 app-image app-image-top" />
							</a>

							<a href="https://itunes.apple.com/us/app/vowpay/id1095042185?ls=1&amp;mt=8" target="_blank" rel="noopener noreferrer">
								<img src="./app-store.png" alt="placeholder text" className="mt-5 app-image app-image-top" />
							</a>
						</div>
					</div>
				</div>

				<div className="calculater-section">
					<Calculator />
				</div>
			</section>

			<hr className="half-line mt-5 mb-5" />

			<section className="section-2">
				<div className="d-flex justify-content-start">
					<h3 className="theme-text-color f-600 how-to-send">How To Send Using Vowpay </h3>
				</div>

				<div className="send-money-section">
					<div className="col-sm-12 col-md-4 d-flex flex-column align-items-start mr-3 vowpay-usage create-account">
						<h3 className="d-flex align-items-center text-left theme-text-color account-section-heading">
							<img src="./add-user.png" alt="placeholder text" className="heading-img" />
							<span> Create an account{" "}</span>
						</h3>

						<div className="d-flex justify-content-center align-items-center mb-3 section-2-text mt-4 theme-light-text text-left">
							<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>
							<span>
								Enter your mobile number, name residential and email address.{" "}
							</span>
						</div>

						<div className="d-flex align-items-center mb-3 section-2-text theme-light-text text-left">
							<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>
							<span>Send us your proof of identity and address.{" "}</span>
						</div>

						<div className="d-flex align-items-center text-left mb-3 section-2-text theme-light-text">
							<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>
							<span>You are good to go.{" "}</span>
						</div>
					</div>

					<div className="col-sm-12 col-md-3 d-flex flex-column align-items-start mr-3 vowpay-usage money-transfer">
						<h3 className="d-flex align-items-center text-left theme-text-color account-section-heading">
							<img src="./money-transfer.png" alt="placeholder text" className="heading-img" />
							<span>Transfer Money {" "}</span>
						</h3>

						<div className="d-flex align-items-center text-left mb-3 mt-4 section-2-text theme-light-text vowpay-usage ">
							<img src="./bullet.svg" width="10px" height="10px" className="bullet-img" alt="placeholder text"/>
							<span>Enter amount to send.{" "}</span>
						</div>

						<div className="d-flex align-items-center text-left mb-3 section-2-text theme-light-text">
							<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>
							<span> Create a new beneficiary.{" "}</span>
						</div>

						<div className="d-flex align-items-center text-left mb-3 section-2-text theme-light-text">
							<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>
							<span>Make payment.</span>
						</div>
					</div>

					<div className="col-sm-12 col-md-3 d-flex flex-column align-items-start track-transfer">
						<h3 className="d-flex align-items-center text-left theme-text-color account-section-heading">
							<img src="./time-tracking.png" alt="placeholder text" className="heading-img"/>
							<span>Track Transfer{" "}</span>
						</h3>

						<div className="d-flex align-items-center text-left mb-3 mt-4 section-2-text theme-light-text">
							<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>
							<span>Active updates on transfer{" "}</span>
						</div>

						<div className="d-flex align-items-center text-left mb-3 section-2-text theme-light-text">
							<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>
							<span>Receive confirmation{" "}</span>
						</div>
					</div>
				</div>
			</section>
			
			<section className="d-flex flex-wrap justify-content-center benefits-section">
				<div className="col-sm-12 col-md-6 vowpay-app-img">
					<img src="./phone-dotted.svg" alt="Benifits Screen" style={{ width: '100%' }}/>
				</div>

				<div className="d-flex flex-column justify-content-center align-items-start col-sm-12 col-md-6 benefits">
					<h3>
						<img src="./benefits.png" alt="placeholder text" className="heading-img"/>
						<span>Benefits</span>
					</h3>

					<div className="mb-3 mt-3 theme-light-text benefits-sec-text">
						<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>{" "}
						<div className="text-left">Instant Transfer</div>
					</div>

					<div className="mb-3 theme-light-text benefits-sec-text">
						<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>{" "}
						<div className="text-left">Transfer directly to bank account or choose cash payout</div>
					</div>

					<div className="mb-3 theme-light-text benefits-sec-text">
						<img src="./bullet.svg" alt="placeholder text" className="bullet-img"/>{" "}
						Track transfer with live in-app updates
					</div>
				</div>
			</section>

			<section>
				<GetStarted />
			</section>
		</div>
		);
	}
}

export default Home;
