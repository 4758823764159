import React from "react";

import "./about-us.css";
import GetStarted from "../get-started/get-started";


class About extends React.Component<any, any> {

	render() {
		return (
			<div>
				<div className="d-flex text-left flex-column justify-content-start theme-text-color about-screen">
					<div>
						<h2 className="f-600 theme-text-color mb-4"> About Us </h2>
					</div>

					<p className="font-adjust theme-text-color mb-4">
						Vowpay is an international money transfer app for sending money from United Kingdom to
						Pakistan. The app is available on App Store and Google Play with instant Bank account
						transfers and Cash out facilities.
					</p>

					<p className="font-adjust theme-text-color mb-4">
						Vowpay offers three unique value services for sending money to family and friends in
						Pakistan; these include instant transfers, competitive rates and transfer fees.
					</p>

					<p className="font-adjust theme-text-color mb-4">
						Vowpay provides a secure platform that delivers the entire money transfer process
						through an app, which is both convenient and time saving. The app eliminates the
						time-consuming process of visiting agents and banks, and instead allows the customer to
						complete the process conveniently using only a few clicks.
					</p>
					<p className="font-adjust theme-text-color mb-4">
						Vowpay is authorised and regulated by the Financial Conduct Authority in the United
						Kingdom FRN 738233. Company number 09200297.
					</p>
					<div className="d-flex">
						<a className="terms-condition" 
							href="https://stg-vowpay-documents-public.s3.eu-west-2.amazonaws.com/Vowpay+Terms+and+Conditons+Final+080921.pdf" 
							target="_blank" rel="noopener noreferrer">Terms & Conditions
						</a>
						<a className="ml-5 terms-condition" 
							href="https://stg-vowpay-documents-public.s3.eu-west-2.amazonaws.com/Privacy+Policy+-+Vowpay+Limited+Final+110322.pdf" 
							target="_blank" rel="noopener noreferrer">Privacy Policy
						</a>
					</div>
				</div>
				
				<div className="d-flex justify-content-center flex-row">
					<GetStarted />
				</div>
			</div>
		);
	}
}

export default About;
