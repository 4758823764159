import React from "react";
import "./faq.css";

class FAQ extends React.Component {
  render() {
    return (
      <div className="faqs-container">
        <div className="faqs-heading-container">
			<h1 className="faq-heading"> FAQ's </h1>
			<span className="theme-light-text">Need help with Vowpay and sending money abroad. Here are some FAQs which may help you.</span>
			{/* <input type="search" className="search-ipt mt-2" placeholder="Search you question" /> */}
        </div>

        <div className="faqs-content">
			<div className="flex-container">
				<div className="flex-item">
					<div>
						<h4 className="theme-text-color">
							I am a non-UK resident or UK national can I use Vowpay?
						</h4>
						<p className="theme-light-text">
							Vowpay is only available for UK nationals or if you are not a UK national,
							you must be a UK resident. 
						</p>
					</div>
				</div>

				<div className="flex-item">
					<div>
						<h4 className="theme-text-color">How do I use Vowpay?</h4>
						<p className="theme-light-text">
							Simply complete the registration process on the mobile app, 
							provide documents confirming your identity and address. 
							Once confirmed enter the amount to send, add a beneficiary and <q>Send Money</q>.
						</p>

						<p className="theme-light-text">Get started by downloading the Vowpay app on the App Store or Google Play. </p>
					</div>
				</div>

				<div className="flex-item">
					<div>
						<h4 className="theme-text-color">
							How can I pay for sending money abroad?
						</h4>
						<p className="theme-light-text">
							You can pay via debit card or bank transfer. Vowpay uses open banking 
							so you can make payments via your personal banking app seamlessly. 
						</p>
					</div>
				</div>

				<div className="flex-item second-question">
					<div>
						<h4 className="theme-text-color">Which countries does Vowpay send money to?</h4>
						<p className="theme-light-text">
							At this moment, we currently send to Pakistan, but we are opening more countries soon.
						</p>
					</div>
				</div>

				<div className="flex-item">
					<div>
						<h4 className="theme-text-color">
							How much do you charge?
						</h4>
						<p className="theme-light-text">
							Our currency rates and charges are shown on our website and on the mobile app 
							so you are sure to know the amount the beneficiary will receive and how much it will cost you. 
						</p>
					</div>
				</div>

				<div className="flex-item second-question">
					<div>
						<h4 className="theme-text-color">Is Vowpay secure?</h4>
						<p className="theme-light-text">
							Keeping your personal and transactional data secure is important to us. 
							Your data is fully encrypted and secure. 
						</p>
					</div>
				</div>

				<div className="flex-item">
					<div>
						<h4 className="theme-text-color">
							How long does it take to transfer money abroad?
						</h4>
						<p className="theme-light-text">
							Once your transaction is processed, we aim to ensure your beneficiary will receive your money within the next working day.
							You can track the progress of the transfer via the app.
						</p>
					</div>
				</div>

				<div className="flex-item second-question">
					<div>
						<h4 className="theme-text-color">Can my beneficiary receive cash?</h4>
						<p className="theme-light-text">
							Yes, if your beneficiary does not have bank account to receive money you can select Cash Out option. 
							They will receive a unique PIN number which they can take to any 
							United Bank Limited branch along with their identification documents to collect their money.
						</p>
					</div>
				</div>

				<div className="flex-item">
					<div>
						<h4 className="theme-text-color">
							What are the standard bank account formats?
						</h4>

						<table className="theme-light-text">
							<thead>
								<tr>
									<th>Bank</th>
									<th>Valid Account Length(s)</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>Allied Bank</td>
									<td>14, 20</td>
								</tr>
								<tr>
									<td>Al Baraka Bank</td>
									<td>13, 23, 24, 30</td>
								</tr>
								<tr>
									<td>Askari Bank</td>
									<td>13, 14</td>
								</tr>
								<tr>
									<td>Bank Al Habib</td>
									<td>17</td>
								</tr>
								<tr>
									<td>Bank Alfalah</td>
									<td>12, 14, 18</td>
								</tr>
								<tr>
									<td>Bank of Punjab</td>
									<td>17</td>
								</tr>
								<tr>
									<td>Bank Islamic</td>
									<td>15</td>
								</tr>
								<tr>
									<td>Burj Bank</td>
									<td>16</td>
								</tr>
								<tr>
									<td>Faysal Bank</td>
									<td>9, 10, 11, 12, 13, 14, 16</td>
								</tr>
								<tr>
									<td>HBL</td>
									<td>14, 17</td>
								</tr>
								<tr>
									<td>Habib Metro Bank</td>
									<td>19, 20</td>
								</tr>
								<tr>
									<td>JS Bank</td>
									<td>6</td>
								</tr>
								<tr>
									<td>Meezan Bank</td>
									<td>14, 15</td>
								</tr>
								<tr>
									<td>MCB</td>
									<td>16</td>
								</tr>
								<tr>
									<td>NIB</td>
									<td>15</td>
								</tr>
								<tr>
									<td>Silk Bank</td>
									<td>14</td>
								</tr>
								<tr>
									<td>Soneri Bank</td>
									<td>15</td>
								</tr>
								<tr>
									<td>Standard Chartered</td>
									<td>11, 24</td>
								</tr>
								<tr>
									<td>Summit Bank</td>
									<td>20</td>
								</tr>
								<tr>
									<td>United Bank Limited</td>
									<td>12</td>
								</tr>
								<tr>
									<td>Sindh Bank</td>
									<td>14</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			
        </div>

		<div className="get-started mt-5 mb-3">
			<h3 className="f-600 theme-text-color mb-4"> Get The App - Get Started </h3>
			
			<div>
				<a href="https://play.google.com/store/apps/details?id=com.vowpay.vowpay" target="_blank" rel="noopener noreferrer">
					<img src="./google-play.png" className="mr-4 app-image" alt="Playstore"/>
				</a>

				<a href="https://itunes.apple.com/us/app/vowpay/id1095042185?ls=1&amp;mt=8" target="_blank" rel="noopener noreferrer">
					<img src="./app-store.png" className="app-image" alt="App store"/>
				</a>
			</div>

			<div className="mt-4">
				<img src="./visa_img.png" className="mr-4" alt="Visa"/>
				<img src="./master.png" alt="Master"/>{" "}
			</div>
		</div>
      </div>
    );
  }
}

export default FAQ;
